import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import React, { Fragment, useState } from 'react'

interface DropdownOption {
  value: string | number
  label: string
}

interface DropdownProps {
  options: DropdownOption[]
  value?: string | number | undefined
  onChange: (value: string | number) => void
  className?: string
  placeholder?: string
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  value = undefined,
  onChange,
  className = 'w-24',
  placeholder = '',
}) => {
  const [selected, setSelected] = useState<DropdownOption | undefined>(
    options.find((option) => option.value === value),
  )

  const handleChange = (option: DropdownOption) => {
    setSelected(option)
    onChange(option.value)
  }

  return (
    <Listbox value={selected} onChange={handleChange}>
      {({ open }) => (
        <div className={`relative rounded-5px ${className}`}>
          <Listbox.Button className="relative w-full h-10 py-2 pl-3 pr-10 text-left bg-white rounded-md border border-color-blue cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:border-blue-500">
            <span className="truncate font-bold">
              {value ?? (selected ? selected.label : placeholder)}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronDownIcon
                className="w-5 h-5 color-blue-f"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none">
              {options.map((option) => (
                <Listbox.Option
                  key={option.value}
                  className={`${
                    option.value === value ? 'bg-blue-200 ' : ''
                  }cursor-default select-none relative py-2 pl-3 pr-9 font-bold`}
                  value={option}
                >
                  <span className={'truncate'}>{option.label}</span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}

export default Dropdown
