import { convertJapNumbers } from '~/utils'
import { currentYear } from '~/utils/constants'

export const getListData = (
  start: number,
  end: number,
  isAscending = true,
): { value: number; label: string }[] => {
  const list: { value: number; label: string }[] = []

  if (isAscending) {
    for (let i = start; i <= end; i++) {
      if (i < 10) {
        list.push({ value: i, label: `0${i}` })
      } else {
        list.push({ value: i, label: i.toString() })
      }
    }
  } else {
    for (let i = end; i >= start; i--) {
      if (i < 10) {
        list.push({ value: i, label: `0${i}` })
      } else {
        list.push({ value: i, label: i.toString() })
      }
    }
  }

  return list
}

export const inputEleParser = (displayValue: string | undefined) => {
  return convertJapNumbers(displayValue as string)
}

export const yearSelections = getListData(1970, currentYear, false)
export const monthSelections = getListData(1, 12)
export const daySelections = getListData(1, 31)
